import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router';

export type Props = {
  isAllowed: boolean;
  children: ReactElement;
  redirectPath?: string;
};

const ProtectedRoute = ({ isAllowed, children, redirectPath = '/404' }: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
