import { PropsWithChildren, useMemo } from 'react';
import { Navigate, useLoaderData, useParams, useRouteLoaderData } from 'react-router';
import { Box, Button, Link, List, ListItem, Paper, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { User } from '../App';
import { AllocatedTeachingUnit } from '../api';
import Practicals from './Practicals';

const DAY_SORT_MAP = new Map([
  ['THU', 0],
  ['FRI', 1],
  ['SAT', 2],
  ['SUN', 3],
  ['MON', 4],
  ['TUE', 5],
  ['WED', 6]
]);

const calculateSortValue = (teachingUnit: AllocatedTeachingUnit) => {
  return DAY_SORT_MAP.get(teachingUnit.timeSlots[0].day)!;
};

const groupBy = <Element, Key extends string>(
  ungrouped_arr: Element[],
  getKey: (element: Element) => Key
) =>
  ungrouped_arr.reduce(
    (groups, element) => {
      (groups[getKey(element)] ||= []).push(element);
      return groups;
    },
    {} as Record<Key, Element[]>
  );

const sortObjectByKeys = (unsortedObject: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(unsortedObject).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
  );

export const MyPracticalsRedirect = () => {
  const user = useRouteLoaderData('authenticated') as User;

  return <Navigate to={`/my-practicals/${user.crsid}`} replace />;
};

const AllocationForTag: React.FC<PropsWithChildren> = ({ children }) => (
  <Paper elevation={0} sx={{ background: '#F4F5F7', width: { xs: '100%', sm: 'fit-content' } }}>
    <Box px={2} py={1}>
      {children}
    </Box>
  </Paper>
);

const SectionHeader: React.FC<PropsWithChildren> = ({ children }) => (
  <Typography variant="h4" fontWeight={400} sx={{ fontSize: { xs: 28, md: 24 } }} mt={4} mb={1}>
    {children}
  </Typography>
);

const OrderListItem: React.FC<PropsWithChildren> = ({ children }) => (
  <ListItem sx={{ display: 'list-item', p: 0, pb: 1 }}>{children}</ListItem>
);

const PageMyPracticals = () => {
  const { crsid } = useParams();
  const allocatedTeachingUnits = useLoaderData() as AllocatedTeachingUnit[];

  const subjectsWithPracticals = useMemo(() => {
    let subjectWithTeachingUnits = groupBy(
      allocatedTeachingUnits,
      (teachingUnit) => teachingUnit.subject.name
    );

    // iterate over all the groups
    for (const [subject, teachingUnits] of Object.entries(subjectWithTeachingUnits))
      subjectWithTeachingUnits[subject] = teachingUnits
        // remove lectures
        .filter((teachingUnit) => teachingUnit.type === 'PRACTICAL')
        // sort practical by day
        .sort((a, b) => calculateSortValue(a) - calculateSortValue(b));

    // sort subjects by name
    return sortObjectByKeys(subjectWithTeachingUnits);
  }, [allocatedTeachingUnits]);

  const practicalCount = useMemo(() => {
    return Object.values(subjectsWithPracticals).reduce(
      (agg, practicals) => (agg = agg + practicals.length),
      0
    );
  }, [subjectsWithPracticals]);

  return (
    <div>
      <AllocationForTag>
        <Typography>
          Allocations for&nbsp;
          <Box component="span" sx={{ display: 'inline', fontWeight: 800 }}>
            {crsid}
          </Box>
        </Typography>
      </AllocationForTag>
      <Typography variant="h3" fontSize={32} fontWeight={400} my={3}>
        Natural Sciences Tripos (Part IA): Practical Allocations
      </Typography>
      <Typography mb={1}>You have {practicalCount} practical allocations.</Typography>
      <Typography>
        The teaching week runs from Thursday to Wednesday. The first Thursday of term is the start
        of week 1. If you have practicals on odd weeks, these take place on weeks 1, 3, 5 and 7.
        Practicals on even weeks take place on weeks 2, 4, 6 and 8.
      </Typography>
      {Object.entries(subjectsWithPracticals).map(([subject, practicals]) => (
        <section key={subject}>
          <SectionHeader>{subject}</SectionHeader>
          <Practicals data={practicals} />
        </section>
      ))}
      <section>
        <SectionHeader>What you need to do next</SectionHeader>
        <List component="ol" sx={{ listStyle: 'decimal', pl: 4 }}>
          <OrderListItem>
            Sign in to{' '}
            <Link href="https://www.timetable.cam.ac.uk" target="_blank" rel="noopener noreferrer">
              timetable.cam.ac.uk
              <OpenInNewIcon sx={{ ml: 0.5, fontSize: '18px', verticalAlign: 'text-bottom' }} />
            </Link>
          </OrderListItem>
          <OrderListItem>
            For each of your subjects (for example 'Biology of Cells'), add all your lectures.
          </OrderListItem>
          <OrderListItem>
            For each of your subjects, add the practical allocations from this page.
          </OrderListItem>
          <OrderListItem>
            If there is a clash, prioritise the lecture and contact your Director of Studies.
          </OrderListItem>
        </List>
        <Typography sx={{ pb: 2 }}>
          Once you have added your lectures and practicals to your timetable, your Director of
          Studies will be able to help you arrange your supervisions.
        </Typography>
        <Button
          href="https://www.timetable.cam.ac.uk"
          target="_blank"
          variant="contained"
          rel="noopener noreferrer"
        >
          View your timetable
          <OpenInNewIcon sx={{ ml: 0.5, fontSize: '18px', verticalAlign: 'text-bottom' }} />
        </Button>
      </section>
    </div>
  );
};

export default PageMyPracticals;
