import React, { useMemo } from 'react';

import {
  Typography,
  Toolbar,
  Button,
  Box,
  IconButton,
  AppBar,
  Container,
  Menu,
  MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BiotechIcon from '@mui/icons-material/Biotech';
import { User } from './App';
import { PERMISSION_BY_PATH } from './permission';
import { Link, useRouteLoaderData } from 'react-router';

export interface Page {
  name: string;
  url: string;
  newTab?: boolean;
  permissions: string[];
}

const pages: Page[] = [
  {
    name: 'Student Choices',
    url: '/student-choices',
    permissions: PERMISSION_BY_PATH.addStudentChoices
  },
  {
    name: 'Allocation',
    url: '/allocation',
    permissions: PERMISSION_BY_PATH.allocation
  },
  {
    name: 'Bulk Choices',
    url: '/bulk-choices',
    permissions: PERMISSION_BY_PATH.bulkChoices
  },
  {
    name: 'Timetable',
    url: '/timetable',
    permissions: PERMISSION_BY_PATH.timetable
  },
  {
    name: 'Admin',
    url: '/admin/',
    newTab: true,
    permissions: PERMISSION_BY_PATH.admin
  }
];

export default function Nav() {
  // anchorEl of the compact nav menu is set dynamically so the menu appears when clicked
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  // Nav is only rendered if we've verified the user has at least one of the permissions required for the admin pages
  // So is definitely loaded
  const user = useRouteLoaderData('authenticated') as User;
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    // Currently event.currentTarget is always the menu open button
    setAnchorElNav(event.currentTarget);
  };
  const closeNavMenu = () => {
    setAnchorElNav(null);
  };

  const filteredPages = useMemo(() => {
    const userPermissions = new Set(user?.permissions);
    return pages.filter((page) => page.permissions.every((perm) => userPermissions.has(perm)));
  }, [user]);

  return (
    <AppBar position="relative" component="nav" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <BiotechIcon
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: 'background.default' }}
          />
          <Typography
            variant="h5"
            component="h1"
            noWrap
            sx={{
              mr: 2,
              display: 'flex',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'background.default'
            }}
          >
            Lab Allocator
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="Open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ ml: 'auto' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={closeNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page: Page) => (
                <MenuItem
                  key={page.url}
                  {...(page.newTab
                    ? {
                        target: '_blank',
                        rel: 'no-opener no-referrer',
                        href: page.url,
                        component: 'a'
                      }
                    : { component: Link, to: page.url })}
                  onClick={() => closeNavMenu()}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
            {filteredPages.map((page: Page) => (
              <Button
                key={page.url}
                sx={{ my: 2, color: 'background.default', display: 'block' }}
                {...(page.newTab
                  ? { target: '_blank', href: page.url, rel: 'no-opener no-referrer' }
                  : { to: page.url, component: Link })}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
