import { Stack, Link, Typography } from '@mui/material';
import { isRouteErrorResponse, Link as RouterLink, Navigate, useRouteError } from 'react-router';
import RootLayout from '../layouts/RootLayout';

const PageError = () => {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Navigate to="404" />;
    }

    if (error.status === 403 && error.data === 'Unauthenticated') {
      return <Navigate to="/sign-in" />;
    }
  }

  return (
    <RootLayout>
      <Stack spacing={2} sx={{ alignItems: 'flex-start', width: '100%' }}>
        <Typography variant="h2" component="h2">
          Unexpected error
        </Typography>
        <Typography>Please try again. If the problem persists, please </Typography>
        <Typography component="div">
          <Link component={RouterLink} to="/">
            Return to home
          </Link>
        </Typography>
      </Stack>
    </RootLayout>
  );
};

export default PageError;
