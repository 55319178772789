import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Menu,
  MenuItem,
  Typography as T
} from '@mui/material';
import Logo from './Logo';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { visuallyHidden } from '@mui/utils';
import PersonIcon from '@mui/icons-material/Person';
import { User } from './App';
import { useNavigate } from 'react-router';

const Header = ({ user }: { user: User | undefined }) => {
  const [userMenu, setUserMenu] = useState<Element | null>(null);
  const navigate = useNavigate();

  return (
    <AppBar color="transparent" position="relative" component="header" elevation={1}>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          my: 'auto',
          py: 2
        }}
      >
        <Logo sx={{ maxWidth: 220 }} />

        {user && (
          <>
            <Button
              aria-controls={userMenu ? 'user-actions-menu' : ''}
              variant="text"
              aria-haspopup="true"
              onClick={(event) => setUserMenu(event.currentTarget)}
              data-test-id="user-menu-dropdown-button"
              sx={{
                ml: 'auto',
                displayPrint: 'none'
              }}
            >
              <Avatar variant="rounded" data-test-id="user-avatar" sx={{ mr: 1.5 }}>
                <Box sx={{ zIndex: 1 }}>
                  {user.firstName || user.lastName ? (
                    <>
                      {user.firstName && user.firstName[0]}
                      {user.lastName && user.lastName[0]}
                    </>
                  ) : (
                    <PersonIcon />
                  )}
                </Box>
              </Avatar>
              {(user.firstName || user.lastName) && (
                <T
                  variant="body2"
                  component="span"
                  sx={{
                    textTransform: 'none',
                    fontWeight: (theme) => theme.typography.fontWeightMedium,
                    marginBottom: 0,
                    marginRight: 1.5,
                    color: 'text.primary',
                    display: ['none', 'block']
                  }}
                  data-test-id="signed-in-user"
                >
                  {user.firstName} {user.lastName}
                </T>
              )}
              <ArrowDropDownIcon />
              <Box sx={visuallyHidden}>{userMenu ? 'Hide' : 'Show'} user menu</Box>
            </Button>
            <Menu
              id="user-actions-menu"
              anchorEl={userMenu}
              open={Boolean(userMenu)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              color="primary"
              onClose={() => setUserMenu(null)}
              aria-expanded={Boolean(userMenu)}
              aria-hidden={!userMenu}
              data-test-id="user-menu"
              hidden={!userMenu}
            >
              <MenuItem
                onClick={() => {
                  navigate('/logout');
                }}
                data-test-id="sign-out-button"
                role="button"
              >
                Sign out
              </MenuItem>
            </Menu>
          </>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
