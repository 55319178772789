import { Stack, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router';

const Page404 = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'flex-start', width: '100%' }}>
      <Typography component="h2" variant="h2">
        404 - Page not found
      </Typography>
      <Typography>
        The page you're trying to reach was not found, or you do not have permissions to view it.
      </Typography>
      <Typography component="div">
        <Link component={RouterLink} to="/">
          Return to home
        </Link>
      </Typography>
    </Stack>
  );
};

export default Page404;
